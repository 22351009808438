import React from 'react';

const PrivacyText = () => {

    return (

        <div>

			<p>

				<strong>Datenschutzerklärung für <a href="https://showroom.3ptechnik.de">https://showroom.3ptechnik.de</a></strong>

			</p><p>

				und die 3P Technik ViShow Applikation für Windows und MacOS

			</p><p>

				(nachfolgend „Datenschutzerklärung“)

			</p><p>

				Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse an unserem Unternehmen und unseren Leistungen. Der Schutz Ihrer Privatsphäre bei der Nutzung unserer Website ist uns wichtig. Über die Verarbeitung Ihrer personenbezogenen Daten und über Ihre Rechte als Betroffener im Rahmen der Nutzung der Website

			</p><p>

				<a href="https://showroom.3ptechnik.de">https://showroom.3ptechnik.de</a>

			</p><p>

				(nachfolgend auch „Website“)

			</p><p>

				und die 3P Technik ViShow Applikation für Windows und MacOS (nachfolgend auch „3P Technik ViShow Applikation” oder “Applikation“)

			</p><p>

				möchten wir,

			</p><p>

				SOLID WHITE design & digital media GmbH <br/>
				Mönchhaldenstraße 27A <br/>
				70191 Stuttgart <br/>

			</p><p>

				(nachfolgend auch „wir“ oder „SOLID WHITE“)

			</p><p>

				als Verantwortliche und zugleich Diensteanbieterin, Sie nachfolgend unterrichten.

			</p><p>

				Die Verarbeitung Ihrer personenbezogenen Daten erfolgt ausschließlich im Rahmen der gesetzlichen Bestimmungen des Datenschutzrechts der Europäischen Union, insbesondere der EU-Datenschutz-Grundverordnung (nachfolgend „DSGVO“) und ergänzend des Bundesdatenschutzgesetzes (nachfolgend „BDSG“) sowie weiterer gesetzlicher Bestimmungen zum Datenschutz (zusammen nachfolgend „Datenschutzgesetze“).

			</p><p>

				Falls Sie selbst einmal einen Blick in die DSGVO werfen möchten, finden Sie diese im Internet unter: <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679">https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679</a>.

			</p><p>

				Diese Datenschutzerklärung gilt nur für die Website abrufbar unter der Domain <a href="https://showroom.3ptechnik.de">https://showroom.3ptechnik.de</a> und die dort herunterladbare 3P Technik ViShow Applikation für Windows und MacOS.
				
			</p><p>

				Die nachfolgenden Hinweise beziehen sich nicht auf andere Websites oder Applikationen von SOLID WHITE oder fremde Websites oder Applikationen anderer Anbieter, auf die von der Website aus verlinkt wird. Die verwendeten Begrifflichkeiten, wie z.B. „personenbezogene Daten“ oder deren „Verarbeitung“ entsprechen den Definitionen in Art. 4 DSGVO.

			</p>

				<hr/>

			<p>

				<strong>1. Gegenstand des Datenschutzes, Rechtsgrundlagen und Quellen</strong>

			</p><p>

				Gegenstand des Datenschutzes sind personenbezogene Daten. Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (sog. betroffene Person) beziehen. Zu Ihren personenbezogenen Daten zählen folglich sämtliche Daten, die eine Identifikation Ihrer Person zulassen, wie bspw. Ihr Name, Ihre Anschrift, Ihre Telefonnummer oder Ihre E-Mail-Adresse. Unter personenbezogene Daten fallen zudem durch die Nutzung unserer Website notwendigerweise entstehende Informationen wie bspw. Beginn, Ende und Umfang der Nutzung oder Ihre IP-Adresse.

			</p><p>

				Personenbezogene Daten werden auch erfasst, soweit Sie uns diese zur Durchführung eines Vertrages mitteilen (Vertragsdaten).

			</p><p>

				Wir verarbeiten Ihre Daten nur dann, wenn eine anwendbare Rechtsvorschrift dies erlaubt. Die Verarbeitung Ihrer Daten werden wir dabei unter anderem auf die folgenden Rechtsgrundlagen stützen:

			</p><p>

				Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a DSGVO): Wir werden bestimmte Daten nur auf der Grundlage Ihrer zuvor erteilten ausdrücklichen und freiwilligen Einwilligung verarbeiten. Sie haben das Recht, Ihre Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen.
				Erfüllung eines Vertrages bzw. Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 S. 1 Buchstabe b DSGVO): Insbesondere zur Anbahnung bzw. Durchführung Ihres Vertragsverhältnisses mit SOLID WHITE, benötigen wir bestimmte Daten von Ihnen.
				Erfüllung einer rechtlichen Verpflichtung (Art. 6 Abs. 1 S. 1 Buchstabe c DSGVO): Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten zur Erfüllung gesetzlicher Verpflichtungen wie z.B. aufsichtsrechtlicher Vorgaben oder handels- und steuerrechtlicher Aufbewahrungspflichten.
				Wahrung berechtigter Interessen (Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO): SOLID WHITE wird bestimmte Daten zur Wahrung ihrer oder der Interessen Dritter verarbeiten. Dies gilt aber nur dann, wenn Ihre Interessen im Einzelfall nicht überwiegen.
				Bitte beachten Sie, dass dies keine vollständige oder abschließende Aufzählung der möglichen Rechtsgrundlagen ist, sondern es sich hierbei lediglich um Beispiele handelt, welche die datenschutzrechtlichen Rechtsgrundlagen transparenter machen sollen. Nähere Angaben zu den Rechtsgrundlagen der einzelnen Datenverarbeitungen auf unserer Website entnehmen Sie bitte den Ausführungen unter den nachfolgenden Ziffern.

			</p><p>

				Diese personenbezogenen Daten stammen aus folgenden Quellen:

			</p><p>

				Überwiegend von Ihnen selbst, insbesondere durch Ihre Nutzung unserer Website, aus Ihren Kontaktanfragen und ggf. Angaben beim Vertragsschluss

				</p><p>

				<strong>2. Server-Log-Daten</strong>

			</p><p>

				Sie können unsere Website besuchen, ohne Angaben zu Ihrer Person zu machen. Durch den Besuch unserer Website können folgende Informationen über den Zugriff gespeichert werden:

			</p><p>

				IP-Adresse des anfragenden Endgerätes,
				abgerufene Datei,
				den http-Antwort-Code,
				die vorherige Website, von der aus Sie die Website besuchen (Referrer-URL),
				Datum, Uhrzeit und Zeitzone der Serveranfrage,
				Browsertyp und -version,
				verwendetes Betriebssystem des anfragenden Endgerätes,
				Suchbegriff mit dem die Website, beispielsweise über Google, gefunden wurde.

			</p><p>

				Diese Daten verarbeiten wir auf Basis von Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO zur Bereitstellung der Website, zur Sicherstellung des technischen Betriebs sowie der Sicherheit unserer informationstechnischen Systeme. Wir verfolgen dabei das Interesse, die Inanspruchnahme unsere Website und deren technische Funktionsfähigkeit zu ermöglichen und dauerhaft aufrecht zu erhalten. Bei Aufruf unsere Website werden diese Daten automatisch verarbeitet. Ohne diese Bereitstellung können Sie unsere Website nicht nutzen. Wir verwenden diese Daten nicht zu dem Zweck, Rückschlüsse auf Ihre Identität zu ziehen.

				</p><p>

				Die automatisiert erhobenen Daten werden in der Regel nach 7 Tagen gelöscht, es sei denn, dass wir sie für die oben genannten Zwecke ausnahmsweise länger benötigen. In einem solchen Fall löschen wir die Daten unverzüglich nach Zweckentfall.

			</p><p>

				Sie können der Erfassung und der Speicherung Ihrer Server-Log-Daten nicht widersprechen, da diese Daten zwingend für einen reibungslosen Betrieb der Website erforderlich sind.

			</p><p>


				<strong>3. Kommunikation per E-Mail und Kontaktformular</strong>

			</p><p>

				Wenn Sie uns per E-Mail kontaktieren, erfolgt die Erhebung, Verarbeitung und Nutzung Ihrer freiwillig mitgeteilten Kontaktdaten (wie bspw. Name, E-Mail-Adresse) nur zweckgerichtet, entweder zur Aufnahme und ggf. Beantwortung Ihrer Anfrage(n) sowie zur technischen Administration.

			</p><p>

				In der Rubrik „Contact“ können Sie sich auch über ein Kontaktformular mit uns in Verbindung setzen. Wenn Sie dieses Kontaktformular nutzen, erheben und speichern wir die folgenden Daten:

			</p><p>

				Name,
				E-Mail-Adresse,
				Nachricht

			</p><p>

				Freiwillig können Sie uns ferner mitteilen:

				</p><p>

				Betreff,
				Firma

			</p><p>

				Die von Ihnen mitgeteilten Daten werden von Ihrem Browser an unseren Server übermittelt, dort in eine E-Mail umgewandelt, welches dann an uns gesendet wird. Die Erhebung, Verarbeitung und Nutzung Ihrer Kontaktdaten erfolgt nur zweckgerichtet zur Aufnahme und ggf. Beantwortung Ihrer Anfrage.

				</p><p>

				Die Verarbeitung von Daten, die im Rahmen einer Kommunikation per Kontaktformular oder per E-Mail übermittelt werden, erfolgt aufgrund Art. 6 Abs. 1 S. 1 Buchstabe b DSGVO, wenn es um die Anbahnung eines Vertragsverhältnisses geht, oder aufgrund Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO. Im letzteren Fall haben wir ein berechtigtes Interesse daran, freiwillig an uns gerichtete Kontaktanfragen zu bearbeiten.

				</p><p>
					
				Wir löschen die von Ihnen angegebenen Daten, sobald der Zweck der Erfassung vollständig wegfällt, vorbehaltlich der Erfüllung fortbestehender gesetzlicher Aufbewahrungspflichten.

				</p><p>
					
				Soweit Ihre Daten aufgrund berechtigter Interessen verarbeitet werden, können Sie jederzeit der Speicherung Ihrer personenbezogenen Daten widersprechen. In diesem Fall werden wir Ihre Daten nicht mehr verarbeiten, sofern wir hieran kein berechtigtes Interesse nachweisen können oder anderweitig zur Speicherung gesetzlich verpflichtet sind. Zur Ausübung Ihres Rechts auf Widerspruch bezüglich der Speicherung wenden Sie sich bitte schriftlich, per Fax oder per E-Mail an uns.

				</p><p>
					
				Bitte beachten Sie jedoch, dass insbesondere bei einer Kommunikation per Kontaktformular und E-Mail keine vollständige Datensicherheit von uns gewährleistet werden kann. Sehen Sie daher bitte davon ab, vertrauliche Informationen, wie Bank- oder Kreditkartendaten etc., darüber zu verschicken. Wir empfehlen wir Ihnen für den Versand von vertraulichen Informationen auf einen sicheren Übertragungsweg, wie etwa Briefpost, zurückzugreifen.

				</p><p>

				<strong>4. Cookies</strong>

				</p><p>
					
				Die Website verwendet Cookies und ähnliche Technologien wie beispielsweise HTML5 Storage (nachfolgend insgesamt „Cookies“), um die Website optimal gestalten zu können. Dies ermöglicht u.a. eine Erleichterung der Navigation und ein hohes Maß an Benutzerfreundlichkeit.

				</p><p>
					
				Cookies sind kleine Identifizierungszeichen, die unser Webserver an Ihren Browser sendet und die Ihr Endgerät bei entsprechender Standard-Einstellung speichert. Cookies sind kleine Identifizierungszeichen, die unser Webserver an Ihren Browser sendet und die Ihr Endgerät bei entsprechender Standard-Einstellung speichert. Diese können genutzt werden, um festzustellen, ob von Ihrem Endgerät bereits eine Kommunikation zu uns bestanden hat.  Dadurch dienen sie dem Zweck, die Nutzung für Sie komfortabler zu gestalten und unser Angebot zu optimieren. Dabei unterscheiden wir danach, ob der Cookie technisch zwingend notwendig ist, ob er durch unsere Website selbst oder durch Dritte gesetzt wurde. Ausführliche Informationen zu Art, Funktion, Zwecken, Rechtsgrundlagen und Widerspruchsmöglichkeiten der Datenverarbeitung bei Cookies entnehmen Sie bitte den nachstehenden Bestimmungen:

				</p><p>
					
				Wenn Sie unsere Website nutzen, werden Sie von uns über den Einsatz von Cookies informiert und stimmen der Nutzung und Speicherung von Cookies auf Ihrem Endgerät zu. Sie können der Speicherung von Cookies jederzeit widersprechen, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Die Verfahren zur Verwaltung und Löschung von Cookies in den Einstellungen Ihres Browsers entnehmen Sie bitte der Hilfe-Funktion Ihres Browsers. Außerdem können Sie sämtliche Cookies auch mittels kostenloser Browser-Add-ons, wie z.B. „Adblock Plus“ (<a href="http://adblockplus.org/de">adblockplus.org/de</a>) in Kombination mit der „EasyPrivacy“-Liste (<a href="http://easylist.to">easylist.to</a>) oder „Ghostery“ (<a href="http://ghostery.com">ghostery.com</a>), deaktivieren. Wenn Sie keine Cookies akzeptieren, kann dies aber zu Funktionseinschränkungen der Website führen.

				</p><p>
					
				<strong>a) Unbedingt erforderliche Cookies</strong>

				</p><p>
					
				Wir verwenden auf unserer Website folgende für die Funktionsweise unserer Website unbedingt erforderlichen Cookies, an deren Speicherung wir ein berechtigtes Interesse haben, da wir unsere Website ansonsten nicht mit bestimmten grundlegenden Funktionalitäten anbieten könnten.

				</p>

				<table className="table">
					<tbody>
						<tr>
							<td>
								<p><strong>Bezeichnung</strong></p>
							</td>
							<td>
								<p><strong>Funktion/Zweck</strong></p>
							</td>
							<td>
								<p><strong>Speicherdauer</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Transiente Cookies</p>
							</td>
							<td>
								<p>Temporäre Speicherung von Daten (z. B. Formular oder Login)</p>
							</td>
							<td>
								<p>Session</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Login Cookies</p>
							</td>
							<td>
								<p>Speicherung der Login-Daten</p>
							</td>
							<td>
								<p>Unbegrenzt</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>YouTube Cookies</p>
							</td>
							<td>
								<p>Eingebettete YouTube Videos</p>
							</td>
							<td>
								<p>Session / 1-24 Monate</p>
							</td>
					</tr>
					<tr>
						<td>
							<p>Atlassian Cookies</p>
						</td>
						<td>
							<p>Fehler Bericht</p>
						</td>
						<td>
							<p>Session / 1-12 Monate</p>
						</td>
					</tr>
					</tbody>
				</table>
				
				<p>

				Die Datenverarbeitung erfolgt zur Wahrung unserer berechtigten Interessen auf der Grundlage von Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO. Unser berechtigtes Interesse ergibt sich insoweit aus den dargestellten Nutzungszwecken.

				</p><p>

				<strong>b) Zusätzliche eigene Cookies</strong>

				</p><p>

				Zusätzliche eigene Cookies, die nicht unbedingt erforderlich sind, um die Website nutzen zu können, (auch „First Party Cookies“ genannt) erfüllen wichtige Aufgaben. Sie ermöglichen ein komfortables Surfen auf unserer Website, wie beispielsweise vorausgefüllte Formulare. Ferner können wir dadurch mit individuell angepassten Angeboten auf Sie eingehen. Wir verwenden auf unserer Website die folgenden zusätzlichen eigenen Cookies:

				</p>
				
				<table className="table">
					<tbody>
						<tr>
							<td>
								<p><strong>Bezeichnung</strong></p>
							</td>
							<td>
								<p><strong>Funktion/Zweck</strong></p>
							</td>
							<td>
								<p><strong>Speicherdauer</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p>-</p>
							</td>
							<td>
								<p>-</p>
							</td>
							<td>
								<p>-</p>
							</td>
						</tr>
					</tbody>
				</table>
				
				<p>

				Die Datenverarbeitung erfolgt zur Wahrung unserer berechtigten Interessen auf der Grundlage von Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO. Unser berechtigtes Interesse ergibt sich insoweit aus den dargestellten Nutzungszwecken.

				</p><p>

				<strong>c) Cookies von Drittanbietern</strong>

				</p><p>

				Zur Integration von Inhalten bzw. Funktionen von Drittanbietern (siehe hierzu nachstehende Ziffern) setzen wir Cookies von Drittanbietern (auch „Third Party Cookies“ genannt), welche es diesen z.B. ermöglichen, die Information zu erhalten, dass Sie diese Website aufgerufen haben. Bitte besuchen Sie die Website der Drittanbieter, um weitere Informationen zu deren Verwendung von Cookies zu erhalten. Wir verwenden folgende Cookies von Drittanbietern:

				</p>
				
				<table className="table">
					<tbody>
						<tr>
							<td>
								<p><strong>Bezeichnung</strong></p>
							</td>
							<td>
								<p><strong>Funktion/Zweck</strong></p>
							</td>
							<td>
								<p><strong>Speicherdauer</strong></p>
							</td>
						</tr>
						<tr>
							<td>
								<p>-</p>
							</td>
							<td>
								<p>-</p>
							</td>
							<td>
								<p>-</p>
							</td>
						</tr>
					</tbody>
				</table>
				
				<p>

				Die Datenverarbeitung erfolgt zur Wahrung unserer berechtigten Interessen auf der Grundlage von Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO. Unser berechtigtes Interesse ergibt sich insoweit aus den dargestellten Nutzungszwecken.

				</p><p>

				Weitere Einzelheiten und Widerspruchsmöglichkeiten der Datenverarbeitung bei Cookies von Drittanbietern entnehmen Sie bitte den nachstehenden Beschreibungen der einzelnen Funktionen, die auf der Verwendung von derartigen Cookies bzw. Cookie-ähnlichen Technologien beruhen.

				</p><p>
					
				<strong>5. 3P Technik ViShow Account für das Control Panel und die Applikation sowie App Analyse</strong>

				</p><p>
					
				Wenn Sie einen 3P Technik ViShow Account anlegen, erfolgt die Erhebung, Verarbeitung und Nutzung Ihrer freiwillig mitgeteilten Kontaktdaten (wie bspw. Name, E-Mail-Adresse) nur zweckgerichtet, entweder zur Aufnahme und ggf. Beantwortung Ihrer Anfrage(n) sowie zur technischen Administration.

				</p><p>
					
				Ihr Nutzer-Name wie Sie ihn in Ihrem Profil unter “Data” angegeben haben, kann zur Bereitstellung von Leistungen in 3P Technik ViShow anderen Nutzern vollständig oder gekürzt angezeigt werden. Dies betrifft z.B. Ihr “Avatar-Label” innerhalb eines “Spaces” oder beim Teilen eines “Spaces” durch Sie (oder mit Ihnen) die “Mitarbeiter”-Liste.	
				
				</p><p>
					
				Wir löschen die von Ihnen angegebenen Daten, sobald der Zweck der Erfassung vollständig wegfällt, vorbehaltlich der Erfüllung fortbestehender gesetzlicher Aufbewahrungspflichten.

				</p><p>
					
				Soweit Ihre Daten aufgrund berechtigter Interessen verarbeitet werden, können Sie jederzeit der Speicherung Ihrer personenbezogenen Daten widersprechen. In diesem Fall werden wir Ihre Daten nicht mehr verarbeiten, sofern wir hieran kein berechtigtes Interesse nachweisen können oder anderweitig zur Speicherung gesetzlich verpflichtet sind. Zur Ausübung Ihres Rechts auf Widerspruch bezüglich der Speicherung wenden Sie sich bitte schriftlich, per Fax oder per E-Mail an uns.

				</p><p>
					
				Um die Nutzung der Applikation analysieren zu können und die Applikation regelmäßig zu verbessern erheben wir folgende Daten:

				</p>
						
				<ul>
					<li>Eindeutige Nutzer ID</li>
					<li>Login Vorgang</li>
					<li>Login Zeitpunkt</li>
				</ul>

				<p>
					
				<strong>6. Empfänger personenbezogener Daten</strong>

				</p><p>
					
				Ihre personenbezogenen Daten werden von uns nur dann an externe Empfänger weitergegeben, sofern dies zur Abwicklung oder Bearbeitung Ihres Anliegens erforderlich ist, uns hierfür Ihre Einwilligung vorliegt oder eine andere gesetzliche Erlaubnis besteht.

				</p><p>
					
				Externe Empfänger können insbesondere sein:

				</p><p>
					
				Auftragsverarbeiter: Dies sind Dienstleister, die wir für die Erbringung von Services einsetzen, beispielsweise in den Bereichen der technischen Infrastruktur und Wartung unserer Website. Solche Auftragsverarbeiter werden von uns sorgfältig ausgewählt und regelmäßig überprüft, um sicherzugehen, dass Ihre Privatsphäre gewahrt bleibt. Diese Dienstleister dürfen die Daten ausschließlich zu den von uns vorgegebenen Zwecken und nach unseren Weisungen verwenden. Wir sind unter Wahrung der gesetzlichen Voraussetzungen von Art. 28 DSGVO dazu befugt, uns solcher Auftragsverarbeiter zu bedienen.
				Öffentliche Stellen: Dies sind Behörden, staatliche Institutionen und andere öffentlich-rechtliche Körperschaften, bspw. Aufsichtsbehörden, Gerichte, Staatsanwaltschaften oder Finanzbehörden. An solche öffentlichen Stellen werden personenbezogene Daten nur aus gesetzlich zwingenden Gründen übermittelt. Rechtsgrundlage einer solchen Übermittlung ist ggf. Art. 6 Abs. 1 S. 1 Buchstabe c DSGVO.
				Nicht-öffentliche Stellen: Diensterbringer und Hilfspersonen, an die Daten auf Basis einer gesetzlichen Verpflichtung oder zur Wahrung berechtigter Interessen übermittelt werden, beispielsweise Steuerberater oder Wirtschaftsprüfer. Die Übermittlung erfolgt dann auf Basis des Art. 6 Abs. 1 S. 1 Buchstabe c und/oder f DSGVO.

				</p><p>
					
				<strong>7. Datenverarbeitung in Drittländern</strong>

				</p><p>
					
				Sofern wir Ihre Daten entsprechend der obigen Ausführungen in Drittländer außerhalb der EU bzw. des EWR übermitteln, stellen wir vor der Weitergabe sicher, dass abgesehen von gesetzlich erlaubten Ausnahmefällen beim Empfänger entweder ein angemessenes Datenschutzniveau besteht oder Sie in die Datenübermittlung einwilligen. Ein angemessenes Datenschutzniveau wird beispielsweise gewährleistet durch eine EU-US-Privacy-Shield-Zertifizierung des Empfängers, den Abschluss von EU-Standardvertragsklauseln oder das Vorliegen sog. Binding Corporate Rules (BCR).

				</p><p>
					
				<strong>8. Sicherheit</strong>

				</p><p>
					
				Wir treffen technische und organisatorische Sicherheitsvorkehrungen, um Ihre personenbezogenen Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend jeweils dem aktuellen Stand der Technik angepasst.

				</p><p>
					
				Ihre im Rahmen der Nutzung unserer Website übertragenen personenbezogenen Daten werden bei uns sicher durch Verschlüsselung übertragen. Wir bedienen uns dabei des Verschlüsselungsprotokolls Transport Layer Security (TLS), weitläufiger bekannt unter der Vorgängerbezeichnung Secure Sockets Layer (SSL).Unsere Beschäftigten sind auf das Datengeheimnis verpflichtet.

				</p><p>
					
				<strong>9. Speicherdauer</strong>

				</p><p>
					
				Wir speichern Ihre personenbezogenen Daten nur solange, wie dies für die Erfüllung der Zwecke erforderlich ist oder – im Falle einer Einwilligung – solange Sie die Einwilligung nicht widerrufen. Im Falle eines Widerspruchs werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, ihre Weiterverarbeitung ist nach den einschlägigen gesetzlichen Bestimmungen erlaubt oder sogar verpflichtend vorgeschrieben (z.B. im Rahmen von handels- und steuerrechtlichen Aufbewahrungspflichten). Wir löschen Ihre personenbezogenen Daten auch dann, wenn wir hierzu aus gesetzlichen Gründen verpflichtet sind.

				</p><p>
					
				Im Übrigen entnehmen Sie die Einzelheiten zur Speicherdauer Ihrer personenbezogenen Daten bitte den jeweiligen Ausführungen unter den vorstehenden Ziffern.

				</p><p>
					
				<strong>10. Ihre Rechte</strong>

				</p><p>
					
				Als von der Datenverarbeitung betroffene Person stehen Ihnen zahlreiche Rechte zu. Im Einzelnen sind dies:

				</p><p>
					
				Auskunftsrecht (Art. 15 DSGVO): Sie haben das Recht, Auskunft über die von uns zu Ihrer Person gespeicherten Daten zu erhalten.
				Berichtigungs- und Löschungsrecht (Art. 16 und Art. 17 DSGVO): Sie können von uns die Berichtigung falscher Daten und – soweit die gesetzlichen Voraussetzungen erfüllt sind – Löschung Ihrer Daten verlangen.
				Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO): Sie können von uns – soweit die gesetzlichen Voraussetzungen erfüllt sind – verlangen, dass wir die Verarbeitung Ihrer Daten einschränken.
				Recht auf Datenübertragbarkeit (Art. 20 DSGVO): Sollten Sie uns Daten auf Basis eines Vertrages oder einer Einwilligung bereitgestellt haben, so können Sie bei Vorliegen der gesetzlichen Voraussetzungen verlangen, dass Sie die von Ihnen bereitgestellten Daten in einem strukturierten und gängigen Format erhalten oder dass wir diese an einen anderen Verantwortlichen übermitteln.
				Widerspruchsrecht gegen Datenverarbeitung aufgrund von berechtigten Interessen (Art. 21 DSGVO): Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Datenverarbeitung durch uns zu widersprechen, soweit diese auf berechtigten Interessen im Sinne von Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO beruht. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen, werden wir die Verarbeitung Ihrer Daten einstellen, es sei denn wir können zwingende schutzwürdige Gründe für die Weiterverarbeitung nachweisen, welche Ihre Rechte überwiegen.
				Widerspruch gegen Cookies: Sie können außerdem jederzeit der Nutzung von Cookies widersprechen. Wenn Sie der Verwendung bestimmter Cookies widersprechen möchten, beachten Sie bitte unsere Ausführungen unter Ziffer 4.
				Widerruf der Einwilligung (Art. 7 DSGVO): Sofern Sie uns eine Einwilligung in die Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen. Die Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zum Widerruf bleibt hiervon unberührt.
				Beschwerderecht bei der Aufsichtsbehörde (Art. 77 DSGVO): Sie können zudem eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen, wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen geltendes Recht verstößt. Sie können sich hierzu wahlweise an die Datenschutzbehörde wenden, die für Ihren Aufenthaltsort, Ihren Arbeitsplatz oder den Ort des mutmaßlichen Verstoßes zuständig ist, oder an die für uns zuständige Datenschutzbehörde. Die für uns zuständige Aufsichtsbehörde für den Datenschutz ist der Landesbeauftragte für den Datenschutz Baden-Württemberg (LfDI), erreichbar unter Königstraße 10a, 70173 Stuttgart, Tel: 0711 615541-0, Fax: 0711 615541-15, E-Mail: poststelle@lfdi.bwl.de, Internet: baden-wuerttemberg.datenschutz.de.
				
				</p><p>
					
				Bei Fragen zum Thema der Verarbeitung Ihrer personenbezogenen Daten, zu Ihren Betroffenenrechte und einer etwaigen erteilten Einwilligung steht Ihnen unser Datenschutzbeauftragter unter den unter Ziffer 13 genannten Kommunikationswegen gerne zur Verfügung. Bitte wenden Sie sich auch zur Ausübung Ihrer Betroffenenrechte direkt an unseren Datenschutzbeauftragten.

				</p><p>

				<strong>11. Unser Datenschutzbeauftragter</strong>

				</p><p>
					
				Wir haben einen betrieblichen Datenschutzbeauftragten benannt. Diesen erreichen Sie wie folgt:

				</p><p>
					
				Herrn Helmut Glaser <br/>
				– Datenschutzbeauftragter – <br/>
				SOLID WHITE design & digital media GmbH <br/>
				Mönchhaldenstraße 27A <br/>
				70191 Stuttgart <br/>

				</p><p>
					
				Fax: +49 (0) 711 400 543 - 90 <br/>
				E-Mail: <a href="mailto:datenschutz@privacy.solidwhite.de">datenschutz@privacy.solidwhite.de</a> <br/>

				</p><p>
					
				<strong>12. Änderungen</strong>

				</p><p>
					
				Von Zeit zu Zeit kann es erforderlich werden, den Inhalt der vorliegenden Datenschutzerklärung anzupassen. Wir behalten uns daher vor, diese jederzeit zu ändern. Sofern für eine Änderung Ihre Einwilligung erforderlich ist, werden wir diese von Ihnen einholen. Wir werden die geänderte Version der Datenschutzerklärung ebenfalls an dieser Stelle veröffentlichen. Wenn Sie unsere Website wieder besuchen, sollten Sie sich daher die Datenschutzerklärung erneut durchlesen.

				</p><p>
					
				Stand: Februar 2021

				</p>					

        </div>

    );
}

export default PrivacyText;