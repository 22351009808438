import React from 'react';

import { ImprintText, PrivacyText, TermsOfUse } from '.';

import { ImprintLink } from '@solidwhite/swcp-base/lib/js/components';


const ImprintAndLegal = () => {

    return (

		<div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
        
			<div className={'container-fluid justify-content-left mt-5'}>

					<div className="card-narrow">

					<div className={'d-flex mb-4'}>

						<h2 className={'page-title ml-0'}>Imprint - Privacy Policy - Legal</h2>

					</div>

					<div className="card rounded shadow mb-3 p-5">
							
						<h4>Table of contents</h4>

						<div className="row mt-5">

							<div className="col">

								<ul>

									<li><a href="#imprint">Imprint</a></li>

									<li><a href="#privacy">Privacy Policy</a></li>

									<li><a href="#terms">Terms of Use</a></li>

								</ul>

							</div>

						</div>

					</div>

					<div className="card rounded shadow mb-3 p-5" id="imprint">
							
						<h4>Imprint</h4>

						<div className="row mt-5">

							<div className="col">

								<ImprintText />

							</div>

						</div>

					</div>

					<div className="card rounded shadow mb-3 p-5" id="privacy">
							
						<h4>Privacy Policy</h4>

						<div className="row mt-5">

							<div className="col">

								<PrivacyText />

							</div>

						</div>

					</div>

					<div className="card rounded shadow mb-3 p-5" id="terms">
							
						<h4>Terms of Use</h4>

						<div className="row mt-5">

							<div className="col">

								<TermsOfUse />

							</div>

						</div>

					</div>

				</div>

			</div>

			<ImprintLink />

        </div>

    )
        
}

export default ImprintAndLegal