import React, { Component } from "react";

import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ImprintLink } from "@solidwhite/swcp-base/lib/js/components";

const mapStateToProps = (state) => ({
  application: state.application,
});

class Application extends Component {
  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <div className={"container-fluid justify-content-left mt-5"}>
          <div className="card-narrow">
            <div className={"d-flex mb-4"}>
              <h2 className={"page-title ml-0"}>Application</h2>
            </div>

            <div className="card card-wide rounded shadow mb-3">
              <div className="row m-0">
                <div className="col m-0 p-0">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/vishow_3p_download_image_small.jpg"
                    }
                    className=" rounded-top"
                    alt="Application"
                  />
                </div>
              </div>

              <div className="p-5">
                <div className="row mb-4">
                  <div className="col">
                    <h4>Virtual Showroom</h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <p className="text-muted mb-4">
                      Hier haben Sie die Möglichkeit die einzelnen Lösungen
                      interaktiv zu erleben und die einzelnen Prozesse genau
                      erklärt zu bekommen.
                    </p>

                    <p className="text-muted mb-4 font-italic">
                      Here you have the opportunity to experience the individual
                      solutions interactively and to have the individual
                      processes explained in detail.
                    </p>
                    <a
                      href="https://virtual.3ptechnik.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        type="button"
                        id="downloadAppButton"
                        className="btn btn-primary"
                      >
                        Start Virtual Showroom
                      </button>
                    </a>
                  </div>
                </div>

                {/* <div className="row mt-5 mb-5">
                  <div className="col">
                    <video
                      style={{ objectFit: "contain" }}
                      width="50%"
                      controls
                      preload="metadata"
                    >
                      <source
                        src={
                          process.env.PUBLIC_URL +
                          "/Jansen_Showroom_Tutorial_mitUntertitel_new.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <ImprintLink />
      </div>
    );
  }
}

Application.propTypes = {
  application: PropTypes.object,
};

export default connect(mapStateToProps, null)(Application);
