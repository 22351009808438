import React from 'react';

const ImprintText = () => {

    return (

        <div>
			
			<p>

				3P Technik Filtersysteme GmbH <br/>
				Robert-Bosch-Straße 16-18 <br/>
				73337 Bad Überkingen <br/>
				Deutschland <br/>

			</p><p>

				Tel: +49 (0) 73 34 92 46 0-0 <br/>
				Fax: +49 (0) 73 34 92 46 0-99 <br/>
				<a href="mailto:info@3ptechnik.de">info@3ptechnik.de</a> <br/>

			</p><p>


				Geschäftsführer: Jorge Torras-Piqué <br/>
				Handelsregister: HRB 54 1100 Ulm - Amtsgericht Ulm <br/>
				Sitz der Gesellschaft: Bad Überkingen, Deutschland <br/>
				USt-IdNr.: DE 185255009 <br/>

			</p>
			
        </div>

    );
}

export default ImprintText;